import React, { useEffect, useState } from "react";
import {
    Typography,
    Button,
    Spin,
    Form,
    Descriptions,
    Divider,
    notification,
    Table,
} from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { Excel } from "antd-table-saveas-excel";

const { Title } = Typography;

const EditInvestRequest = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});
    const [userData, setUserData] = useState({});
    const [specifications, setSpecifications] = useState({});
    const [adminResponse, setAdminResponse] = useState({});
    const columns = [
        {
            title: " Question Id",
            dataIndex: "question_id",
        },{
            title: " Question",
            dataIndex: "question",
        },
        {
            title: " Answer",
            dataIndex: "answers",
        }
    ]
    useEffect(() => {
        get(`invest-requests/${id}`).then((res) => {
            setInputsValue({
                'comment': res.data.comment
            });

            setUserData(res.data.user)
            setSpecifications(res.data.specifications)
            setAdminResponse({
                'comment': res.data.comment,
                'contacted_date': res.data.contacted_date,
                'response_by': res.data.response_by,
                'response_time': res.data.response_time,
            })
            setFromLoading(true);
            console.log(res.data.specifications);
        });
    }, [id]);

    const [loading, setLoading] = useState(false);
    const handleClick = () => {
        const excel = new Excel();
        excel
          .addSheet(`invest-request-${userData.name}`)
          .addColumns(columns)
          .addDataSource(specifications, {
            str2Percent: true
          })
          .saveAs(`invest-request-${userData.phone}.xlsx`);
      };
    const onFinish = (values) => {
        setLoading(true);

        put(`invest-requests/${id}`, values)
            .then((res) => {
                notification.success({ message: "Modified successfully" });
                setLoading(false);
                navigate("/invest-requests");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/invest-requests">
                    <ArrowLeftOutlined />
                    Edit Invest Request
                </Link>
            </Title>

            {fromLoading ?

                <>
                    <Divider />
                    <Descriptions title="User Info" bordered colon className="bg-white p-2">
                        <Descriptions.Item label="UserName">
                            {userData.name ?? ""}
                        </Descriptions.Item>
                        <Descriptions.Item label="Telephone">
                            {userData.phone ?? ""}
                        </Descriptions.Item>
                    </Descriptions>
                    <Divider />

                    <Descriptions title="specifications" bordered colon className="bg-white p-2">
                        <div>
                        <button className="btn btn-info" onClick={handleClick}>Export</button>
                        <Divider />
                        <div className="w-100">
                            <Table
                                locale={{ emptyText: "There are no data" }}
                                loading={loading}
                                columns={columns}
                                dataSource={specifications}
                                size="small"
                                responsive
                                pagination={false}
                            />
                        </div>
                        </div>
                        {/*
                            specifications.map((item) => (
                                <>
                                    <Descriptions.Item label="question">
                                        {item.question ?? ''}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="answer" >
                                        {item.answer ?? ''}
                                    </Descriptions.Item>
                                </>
                            ))
                            */}
                    </Descriptions>
                    <Divider />
                    {
                        adminResponse.comment !== '' ?

                            (
                                <Descriptions title="Admin Comment" bordered colon className="bg-white p-2">
                                    <Descriptions.Item label="Comment" bordered>
                                        {adminResponse.comment ?? ''}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Contacted date" bordered >
                                        {adminResponse.contacted_date ?? ''}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Response Time" bordered>
                                        {adminResponse.response_time ?? ''}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Response By" bordered>
                                        {adminResponse.response_by ?? ''}
                                    </Descriptions.Item>
                                </Descriptions>
                            )
                            : ''
                    }

                    <Divider />
                    <Form
                        className="wrapper-content"
                        onFinish={onFinish}
                        labelCol={{
                            span: 7,
                        }}
                        wrapperCol={{
                            span: 15,
                        }}
                        initialValues={{
                            comment: inputsValue.comment,
                        }}
                        autoComplete="off"
                    >
                        <div className="row">
                            <div className="col-lg-12">
                                <Form.Item
                                    label="Comment"
                                    name="comment">
                                    <ReactQuill theme="snow" />
                                </Form.Item>
                            </div>

                            <div className="col-lg-4 offset-lg-4">
                                <Form.Item
                                    wrapperCol={{
                                        offset: 1,
                                        span: 16,
                                    }}
                                >
                                    <Button
                                        loading={loading}
                                        size="large"
                                        block
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {loading ? "Updating..." : "Update"}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </>

                : (
                    <div className="text-center">
                        <Spin size="large" />
                    </div>
                )}
        </div>
    );
};

export default EditInvestRequest;
