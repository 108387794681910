import React, { useState } from "react";
import {
  Input,
  Typography,
  Button,
  Form,
  Switch,
  notification,
  Upload,
  Modal,
} from "antd";
import { post, postFromData } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { GoogleMap, MarkerF, Autocomplete, useLoadScript } from "@react-google-maps/api";
import { getGeocode } from "use-places-autocomplete";
import { GoogleMapsAPI, DefaultLatLng, GoogleMapLibrary } from "../../../api/client-config";

const { Title } = Typography;

const AddAgentProject = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [inputsValue, setInputsValue] = useState({
    is_active: true,
  });

  const handleSwitch = (status, name) => {
    setInputsValue({ ...inputsValue, [name]: status });
  };
  const [fileList, setFileList] = useState([]);
  const handlePreview = async (file) => {
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  // Handle change in file upload
  // const handleChange = ({ fileList }) => setFileList(fileList);
  const handleChange = ({ fileList }) => {
    setFileList(fileList);

    // Filter imageUrls to include only URLs of images that are still in fileList
    const currentUrls = fileList.map((file) => file.url).filter(Boolean);
    setImageUrls(currentUrls);  // Update imageUrls to reflect only current files
  };

  // Send image binary data to the server before uploading
  const beforeUpload = async (file) => {

    try {
      const response = await postFromData('/upload-image', { 'image': file, 'store_path': 'gent-projects' });

      // Assuming response returns the image path, store it in the imageUrls array
      const imagePath = response.data;

      setImageUrls((prev) => [...prev, imagePath]);

      // Update fileList with uploaded image URL
      file.url = imagePath;
      return false; // Prevent Ant Design from auto-uploading the file
    } catch (error) {
      console.error('Error uploading file:', error);
      return false;
    }
  };

  const [mapPosition, setMapPosition] = useState(DefaultLatLng);

  const [markerPosition, setMarkerPosition] = useState(DefaultLatLng);

  const [mapRef, setMapRef] = useState(null);

  const [acRef, setAcRef] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [currentAddress, setCurrentAddress] = useState("");
  const [previewImage, setPreviewImage] = useState('');

  const [fileArList, setFileArList] = useState(inputsValue.file_ar ? [{ url: inputsValue.file_ar.file }] : []);
  const [fileEnList, setFileEnList] = useState(inputsValue.file_en ? [{ url: inputsValue.file_en.file }] : []);

// Handle Arabic file upload change
  const handleChangeAr = ({ fileList }) => {
    setFileArList(fileList);
  };

  // Handle English file upload change
  const handleChangeEn = ({ fileList }) => {
    setFileEnList(fileList);
  };
  const [imageUrls, setImageUrls] = useState([]);
  const handleOnLoad = (map) => {
    setMapRef(map);
  };

  const handleOnLoadAC = (autocomplete) => {
    setAcRef(autocomplete);
  };

  const handleOnCenterChanged = () => {
    if (mapRef) {
      const centerMap = mapRef.getCenter();
      const centerLat = centerMap?.lat();
      const centerLng = centerMap?.lng();
      if (centerLat && centerLng) {
        setMarkerPosition({
          lat: centerLat,
          lng: centerLng
        });
      }
    }
  };

  const handleOnDragEnd = async () => {
    if (!mapRef) return;
    const centerMap = mapRef.getCenter();
    const res = await getGeocode({ centerMap });
    const streetAddress = res.find(({ types }) =>
      types.includes("street_address")
    );
    setCurrentAddress(
      streetAddress?.formatted_address ?? res[0].formatted_address
    );
  };

  const handleOnACSelected = () => {
    if (acRef) {
      const selectedPlace = acRef.getPlace();
      const selectedLat = selectedPlace.geometry?.location?.lat();
      const selectedLng = selectedPlace.geometry?.location?.lng();
      if (selectedLat && selectedLng) {
        setMapPosition({
          lat: selectedLat,
          lng: selectedLng
        });
      }
    }
  };

  const { isLoaded } = useLoadScript({
    id: "map",
    googleMapsApiKey: GoogleMapsAPI,
    libraries: GoogleMapLibrary
  });

  const handleBeforeUploadAr = (e) => {
    postFromData('/upload-file', { 'file': e, 'store_path': 'agent-projects' }).then(res => {
      setInputsValue({ ...inputsValue, file_ar: res.data })
    })
      .catch((err) => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  }
  const handleBeforeUploadEn = (e) => {
    postFromData('/upload-file', { 'file': e, 'store_path': 'agent-projects' }).then(res => {
      setInputsValue({ ...inputsValue, file_en: res.data })
    })
      .catch((err) => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const onFinish = (values) => {
    const data = {
      ...values,
      ...inputsValue,
      photos: imageUrls,
      'latitude': markerPosition.lat,
      'longitude': markerPosition.lng
    };

    post("agent-projects", data).then((res) => {
      notification.success({ message: "Added successfully" });
      setLoading(false);
      navigate("/agent-projects");
    })
      .catch((err) => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-projects">
          <ArrowLeftOutlined />
        </Link>
        Add Project
      </Title>
      <Form
        className="wrapper-content"
        onFinish={onFinish}
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 15,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <div className="row">
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Name Ar"
              name="name_ar"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Name En "
              name="name_en"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Developer Ar"
              name="developer_ar"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Developer En "
              name="developer_en"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Location Ar"
              name="location_ar"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Location En "
              name="location_en"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                }
              ]}
            >
              <Input type="number"
                placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Order"
              name="order"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                }
              ]}
            >
              <Input type="number"
                placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
                label="File Ar"
                name="file_ar"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  }
                ]}
            >
              <Upload
                  fileList={fileArList}
                  onChange={handleChangeAr}
                  beforeUpload={handleBeforeUploadAr}
                  listType="text"
                  maxCount={1}
                  onRemove={() => setFileArList([])} // Clear file on remove
              >
                {fileArList.length === 0 && (
                    <Button icon={<UploadOutlined />}>Click to upload Arabic file</Button>
                )}
              </Upload>
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
                label="File En"
                name="file_en"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  }
                ]}
            >
              <Upload
                  fileList={fileEnList}
                  onChange={handleChangeEn}
                  beforeUpload={handleBeforeUploadEn}
                  listType="text"
                  maxCount={1}
                  onRemove={() => setFileEnList([])} // Clear file on remove
              >
                {fileEnList.length === 0 && (
                    <Button icon={<UploadOutlined />}>Click to upload English file</Button>
                )}
              </Upload>
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item label="Active " name="is_active">
              <Switch
                defaultChecked
                onChange={(status) => handleSwitch(status, "is_active")}
              />
            </Form.Item>
          </div>
          <div className="col-lg-12">
            <Upload
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              beforeUpload={beforeUpload}
            >
              {fileList.length >= 12 ? null : uploadButton}
            </Upload>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={() => setPreviewOpen(false)}
            >
              <img
                alt="example"
                style={{ width: '100%' }}
                src={previewImage}
              />
            </Modal>
          </div>

          <div className="col-lg-12">
            <Form.Item label="Landmark Position" className="mb-0">

            </Form.Item>


            {isLoaded && (
              <>
                <Autocomplete
                  onLoad={handleOnLoadAC}
                  onPlaceChanged={handleOnACSelected}
                >
                  <input
                    type="text"
                    style={{
                      boxSizing: `border-box`,
                      border: `1px solid transparent`,
                      width: `100%`,
                      height: `50px`,
                      padding: `0 12px`,
                      marginBottom: `15px`,
                      borderRadius: `3px`,
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`
                    }}
                  />
                </Autocomplete>
                <p>{currentAddress}</p>
                <GoogleMap
                  onLoad={handleOnLoad}
                  mapContainerStyle={{
                    width: "100%",
                    height: "400px"
                  }}
                  center={mapPosition}
                  zoom={13}
                  onCenterChanged={handleOnCenterChanged}
                  onDragEnd={handleOnDragEnd}
                >
                  <MarkerF position={markerPosition} />
                </GoogleMap>
              </>
            )}
          </div>
        </div>
        <div className="row mt-5 pt-2">
          <div className="col-lg-4 offset-lg-4">
            <Form.Item
              wrapperCol={{
                offset: 1,
                span: 16,
              }}
            >
              <Button
                loading={loading}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddAgentProject;
