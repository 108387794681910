import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Spin, Form, Switch, Upload, notification } from "antd";
import { get, postFromData, put } from "../../../api/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Title } = Typography;

const Settings = () => {
    const [fromLoading, setFromLoading] = useState(false);
    const navigate = useNavigate();
    let { id } = useParams();

    const [inputsValue, setInputsValue] = useState({
        'translations_ar': [],
        'translations_en': [],
        'key': '',
        // 'name': '',
    });
    const [initValues, setInitValues] = useState({});

    useEffect(() => {
        get(`page-translations/${id}`).then((res) => {
            setFromLoading(true);
            setInputsValue({ ...res.data, 'imgAr': res.data.translations_ar[1].value, 'imgEn': res.data.translations_en[1].value });
            const initValuesNewAR = {}
            res.data.translations_ar.map((item) => {
                initValuesNewAR[item.key] = item.value;

            });
            const initValuesNewEN = {}
            res.data.translations_en.map((item) => {
                initValuesNewEN[item.key] = item.value;

            });
            setInitValues({
                ...initValues,
                'translations_ar': initValuesNewAR,
                'translations_en': initValuesNewEN,
                'key': res.data.key,
            })


            setFileListAr(
                [
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: res.data.translations_ar[1].value,
                    },
                ]
            )
            setFileListEn(
                [
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: res.data.translations_en[1].value,
                    },
                ]
            )

        });
    }, []);

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
    }


    const handleBeforeUploadAr = (e) => {

        postFromData('/upload-image', {
            'image': e, 
            'store_path': `page-translations/${inputsValue.name.replaceAll(' ', '-').toLocaleLowerCase()}`,
            'old_path': inputsValue.translations_ar[1].value 
        }).then(res => {
            setInputsValue({ ...inputsValue, imgAr: process.env.REACT_APP_BASE_IMAGE_URL + res.data })
        })
        .catch((err) => {
            notification.error({
                message: "Error!",
                description: err.response.data.message,
            })
            setLoading(false);
        })
    }
    const handleBeforeUploadEn = (e) => {

        postFromData('/upload-image', { 
            'image': e,
            'store_path': `page-translations/${inputsValue.name.replaceAll(' ', '-').toLocaleLowerCase()}`,
            'old_path': inputsValue.translations_en[1].value 
        }).then(res => {
            setInputsValue({ ...inputsValue, imgEn: process.env.REACT_APP_BASE_IMAGE_URL + res.data })
        })
        .catch((err) => {
            notification.error({
                message: "Error!",
                description: err.response.data.message,
            });
            setLoading(false);
        })
    }

    const [fileListAr, setFileListAr] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
    ]);
    const [fileListEn, setFileListEn] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
    ]);




    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {

        const newArrAR = inputsValue.translations_ar;
        newArrAR.map((item) => {
            if (item.type === "image") {
                item.value = inputsValue.imgAr;
            }
            else {
                item.value = values.translations_ar[item.key]
            }
        })

        const newArrEN = inputsValue.translations_en;
        newArrEN.map((item) => {
            if (item.type === "image") {
                item.value = inputsValue.imgEn;
            }
            else {
                item.value = values.translations_en[item.key]
            }
        })


        put(`page-translations/${id}`, { 'key': values.key, 'translations_ar': newArrAR, 'translations_en': newArrEN }).then((res) => {
            notification.success({message:"Modified successfully"});
            setLoading(false);
            navigate("/pages");

        })
        .catch((err) => {
            notification.error({
                message: "Error!",
                description: err.response.data.message,
            });
            setLoading(false);
        });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/pages">
                    <ArrowLeftOutlined />
                </Link>
                Edit {inputsValue.name} Translations
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    layout="vertical"
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 20,
                    }}
                    initialValues={
                        initValues
                    }
                    autoComplete="off"
                >
                    <div className="row">

                        <div className="col-lg-12 d-none">
                            <Form.Item
                                label="key"
                                name="key"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input disabled placeholder="Type..." />
                            </Form.Item>
                        </div>

                        <div className='col-lg-6'>
                            <Title level={4} className="text-main mb-2 border-bottom pb-2">
                                Inputs Ar
                            </Title>
                            <Form.List name="translations_ar">
                                {() => (
                                    <>

                                        {inputsValue.translations_ar.map((item) => (
                                            <>

                                                <Form.Item
                                                    label={item.key.replaceAll('-', ' ').toLocaleLowerCase()}
                                                    name={item.key}
                                                    rules={[
                                                        {
                                                            required: item.type === "checkbox" ? false : item.key === "page-image" ? false : true,
                                                            message: "This field is required!",
                                                        },
                                                    ]}
                                                >
                                                    {item.type === "checkbox" ? (
                                                        <>
                                                            <Switch defaultChecked={item.value} />
                                                        </>
                                                    )
                                                        : item.type === "textarea" ? (
                                                            <>
                                                                <ReactQuill theme="snow" value={item.value} />
                                                            </>
                                                        )
                                                            : item.type === "image" ? (
                                                                <>
                                                                    <Upload
                                                                        beforeUpload={handleBeforeUploadAr}
                                                                        listType="picture"
                                                                        maxCount={1}
                                                                        defaultFileList={fileListAr}
                                                                        onPreview={onPreview}

                                                                    >
                                                                        <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                                                                    </Upload>
                                                                </>
                                                            )
                                                                : (
                                                                    <>
                                                                        <Input placeholder="Type..." />
                                                                    </>
                                                                )}
                                                </Form.Item>
                                            </>
                                        ))}
                                    </>
                                )}
                            </Form.List>

                        </div>
                        <div className='col-lg-6'>
                            <Title level={4} className="text-main mb-2 border-bottom pb-2">
                                Inputs En
                            </Title>
                            <Form.List name="translations_en">
                                {() => (
                                    <>

                                        {inputsValue.translations_en.map((item) => (
                                            <>

                                                <Form.Item
                                                    label={item.key.replaceAll('-', ' ').toLocaleLowerCase()}
                                                    name={item.key}
                                                    rules={[
                                                        {
                                                            required: item.type === "checkbox" ? false : item.key === "page-image" ? false : true,
                                                            message: "This field is required!",
                                                        },
                                                    ]}
                                                >
                                                    {item.type === "checkbox" ? (
                                                        <>
                                                            <Switch defaultChecked={item.value} />
                                                        </>
                                                    ) : item.type === "textarea" ? (
                                                        <>
                                                            <ReactQuill theme="snow" value={item.value} />
                                                        </>
                                                    )
                                                        : item.type === "image" ? (
                                                            <>
                                                                <Upload
                                                                    beforeUpload={handleBeforeUploadEn}
                                                                    listType="picture"
                                                                    maxCount={1}
                                                                    defaultFileList={fileListEn}
                                                                    onPreview={onPreview}

                                                                >
                                                                    <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                                                                </Upload>
                                                            </>
                                                        )
                                                            : (
                                                                <>
                                                                    <Input placeholder="Type..." />
                                                                </>
                                                            )}
                                                </Form.Item>
                                            </>
                                        ))}
                                    </>
                                )}
                            </Form.List>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading ? "Updating..." : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default Settings;
