import React, { useState } from "react";
import { Input, Typography, Button, Form, Switch, Upload, notification, Select } from "antd";
import { post, postFromData } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";

import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";

const { Title } = Typography;

const AddSliderAgent = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({
        is_active: true,
        type: ''
    });


    const handleSwitch = (status, name) => {
        setInputsValue({ ...inputsValue, [name]: status });
    };

    const handleBeforeUploadSmallAr = (e) => {
        postFromData('/upload-image', { 'image': e, 'store_path': 'onboarding-sections' }).then(res => {
            setInputsValue({ ...inputsValue, small_image_ar: res.data })
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }
    const handleBeforeUploadSmallEn = (e) => {
        postFromData('/upload-image', { 'image': e, 'store_path': 'onboarding-sections' }).then(res => {
            setInputsValue({ ...inputsValue, small_image_en: res.data })
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }
    const handleBeforeUploadLargeEn = (e) => {
        postFromData('/upload-image', { 'image': e, 'store_path': 'onboarding-sections' }).then(res => {
            setInputsValue({ ...inputsValue, large_image_en: res.data })
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }

    const handleBeforeUploadLargeAr = (e) => {
        postFromData('/upload-image', { 'image': e, 'store_path': 'onboarding-sections' }).then(res => {
            setInputsValue({ ...inputsValue, large_image_ar: res.data })
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }

    const handleType = (e) => {

        setInputsValue({ ...inputsValue, type: e })
    }


    const onFinish = (values) => {
        setLoading(true);

        post("onboarding-sections", {
            ...values,
            ...inputsValue,
        })
            .then((res) => {
                notification.success({ message: "Added successfully" });
                setLoading(false);
                navigate("/sliders-agent");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/sliders-agent">
                    <ArrowLeftOutlined />
                </Link>
                Add Agent Slider
            </Title>
            <Form
                className="wrapper-content"
                onFinish={onFinish}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className="row">
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label='Small image ar'
                            name='small_image_ar'
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                },
                            ]}
                        >
                            <Upload
                                beforeUpload={handleBeforeUploadSmallAr}
                                listType="picture"
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                            </Upload>
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label='Small image en'
                            name='small_image_en'
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                },
                            ]}
                        >
                            <Upload
                                beforeUpload={handleBeforeUploadSmallEn}
                                listType="picture"
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                            </Upload>
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label='Large image ar'
                            name='large_image_ar'
                        >
                            <Upload
                                beforeUpload={handleBeforeUploadLargeAr}
                                listType="picture"
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                            </Upload>
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label='Large image en'
                            name='large_image_en'
                        >
                            <Upload
                                beforeUpload={handleBeforeUploadLargeEn}
                                listType="picture"
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                            </Upload>
                        </Form.Item>
                    </div>

                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Order "
                            name="order"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </div>

                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Active " name="is_active">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_active")}
                            />
                        </Form.Item>
                    </div>

                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                className="w-100"
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                placeholder="Select"
                                onChange={handleType}
                                options={[
                                    { value: 'investor', label: "Investor" },
                                    { value: 'agent', label: "Agent" },
                                    { value: 'url', label: "Url" },
                                ]}
                            />
                        </Form.Item>
                    </div>

                    {
                        inputsValue.type == 'url' && <>
                            {/* Item */}
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Url ar"
                                    name="url_ar"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Type..." />
                                </Form.Item>
                            </div>
                            {/* Item */}
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Url en"
                                    name="url_en"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Type..." />
                                </Form.Item>
                            </div>
                            {/* Item */}
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Button text ar"
                                    name="button_text_ar"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Type..." />
                                </Form.Item>
                            </div>
                            {/* Item */}
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Button text en"
                                    name="button_text_en"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Type..." />
                                </Form.Item>
                            </div>

                        </>
                    }

                </div>
                <div className="row">
                    <div className="col-lg-4 offset-lg-4">
                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size="large"
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                {loading ? "Saving..." : "Save"}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddSliderAgent;
