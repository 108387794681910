import React, { useState, useEffect } from "react";
import {
    Input,
    Typography,
    Button,
    Select,
    Form,
    Switch,
    notification,
    Upload,
} from "antd";
import { get, post, postFromData } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const { Title } = Typography;

const AddStatistic = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({
        is_active: true,
        forecastable: true,
        compoudable: true,
        is_percentage: true,
        is_soon: true,
        is_currency: false,
    });


    const [dataPackages, setDataPackages] = useState([]);
    const [optionPackages, setOptionPackages] = useState([]);
    const [dataCategories, setDataCategories] = useState([]);
    const [optionCategories, setOptionCategories] = useState([]);

    useEffect(() => {
        setOptionPackages(
            dataPackages.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataPackages]);

    useEffect(() => {
        get("get-all-packages").then((res) => {
            setDataPackages(res.data);
        });
    }, []);

    useEffect(() => {
        setOptionCategories(
            dataCategories.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataCategories]);

    useEffect(() => {
        get("get-all-statistic-categories").then((res) => {
            setDataCategories(res.data);
        });
    }, []);


    // const handlePicker = (color, name) => {
    //     setInputsValue({ ...inputsValue, [name]: color.toHexString() });
    // };

    const handleSwitch = (status, name) => {
        setInputsValue({ ...inputsValue, [name]: status });
    };

    const handleBeforeUploadAr = (e) => {
        postFromData('/upload-image', { 'image': e, 'store_path': 'statistics' }).then(res => {
            setInputsValue({ ...inputsValue, video_thumbnail_ar: res.data })
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }

    const handleBeforeUploadEn = (e) => {
        postFromData('/upload-image', { 'image': e, 'store_path': 'statistics' }).then(res => {
            setInputsValue({ ...inputsValue, video_thumbnail_en: res.data })
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }

    const onFinish = (values) => {
        setLoading(true);


        post("statistics", {
            ...values,
            ...inputsValue,
        })
            .then((res) => {
                notification.success({ message: "Added successfully" });
                setLoading(false);
                navigate("/statistics");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/statistics">
                    <ArrowLeftOutlined />
                </Link>
                Add Statistic
            </Title>
            <Form
                className="wrapper-content"
                // layout='vertical'
                onFinish={onFinish}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}
            >
                <div className="row">
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Name Ar"
                            name="name_ar"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Name En "
                            name="name_en"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Video tip ar "
                            name="video_tip_ar"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Video tip en "
                            name="video_tip_en"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Video url ar "
                            name="video_url_ar"
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Video url en "
                            name="video_url_en"
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label='Video thumbnail ar'
                            name='video_thumbnail_ar'
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                },
                            ]}
                        >
                            <Upload
                                beforeUpload={handleBeforeUploadAr}
                                listType="picture"
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                            </Upload>
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label='Video thumbnail en'
                            name='video_thumbnail_en'
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                },
                            ]}
                        >
                            <Upload
                                beforeUpload={handleBeforeUploadEn}
                                listType="picture"
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                            </Upload>
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="More info url ar "
                            name="more_info_url_ar"
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="More info url en "
                            name="more_info_url_en"
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="More info ar "
                            name="more_info_ar"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <ReactQuill theme="snow" />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="More info en "
                            name="more_info_en"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <ReactQuill theme="snow" />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    {/* <div className="col-lg-6">
                        <Form.Item
                            label="Min value map color "
                            name="min_value_map_color"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <ColorPicker
                                defaultValue="#0277bd"
                                onChange={(color) => handlePicker(color, "min_value_map_color")}
                                size="large"
                            />
                        </Form.Item>
                    </div> */}
                    {/* Item */}
                    {/* <div className="col-lg-6">
                        <Form.Item
                            label="Max value map color "
                            name="max_value_map_color"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <ColorPicker
                                defaultValue="#0277bd"
                                onChange={(color) => handlePicker(color, "max_value_map_color")}
                                size="large"
                            />
                        </Form.Item>
                    </div> */}
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Category"
                            name="statistic_category_id"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                className="w-100"
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                placeholder="Select"
                                options={optionCategories}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Order "
                            name="order"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input type="number" placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Forecastable" name="forecastable">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "forecastable")}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Compoudable " name="compoudable">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "compoudable")}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Is Percentage " name="is_percentage">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_percentage")}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Decimal Places "
                            name="decimal_places"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                }
                            ]}
                        >
                            <Input type="number"
                                min={0}
                                max={2}
                                placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Soon " name="is_soon">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_soon")}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Currency " name="is_currency">
                            <Switch
                                onChange={(status) => handleSwitch(status, "is_currency")}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Active " name="is_active">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_active")}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            label="Subscription"
                            name="package_ids"
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                className="w-100"
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                placeholder="Select"
                                options={optionPackages}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 offset-lg-4">
                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size="large"
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                {loading ? "Saving..." : "Save"}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddStatistic;
