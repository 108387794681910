import React, { useEffect, useState, useRef } from "react";
import {
  Input,
  Typography,
  Button,
  Spin,
  Form,
  notification,
} from "antd";
import { post } from "../../../api/axios"; // Ensure the post function is correctly imported
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import IntlTelInput from "intl-tel-input/reactWithUtils";
import "intl-tel-input/build/css/intlTelInput.css";
import 'react-intl-tel-input/dist/main.css';

const { Title } = Typography;

const AddAgentAccountManager = () => {
  const navigate = useNavigate();
  const intlTelInputRef = useRef(null); // Reference for IntlTelInput
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // Ensure the reference is available
    if (!intlTelInputRef.current) {
      notification.error({ message: "Phone input not initialized." });
      return;
    }

    const intlTelInputInstance = intlTelInputRef.current.getInstance();

    // Ensure the instance is available
    if (!intlTelInputInstance) {
      notification.error({ message: "Phone input instance not found." });
      return;
    }

    const isValid = intlTelInputInstance.isValidNumber();

    if (!isValid) {
      notification.error({ message: "Invalid phone number!" });
      form.setFields([{ name: "phone_number", errors: ["Invalid phone number"] }]);
      return false;
    }

    const selectedCountryDialCode = intlTelInputInstance.getSelectedCountryData().dialCode;
    const validNumber = intlTelInputInstance.getNumber();

    const newValues = {
      ...values,
      phone_number: validNumber.replace("+" + selectedCountryDialCode, ""),
      country_code: selectedCountryDialCode,
    };

    setLoading(true);

    post("agent-account-managers", newValues)
        .then(() => {
          notification.success({ message: "Added successfully" });
          setLoading(false);
          navigate("/agent-account-managers");
        })
        .catch((err) => {
          notification.error({
            message: "Error!",
            description: err.response?.data?.message,
          });
          setLoading(false);
        });
  };

  return (
      <div>
        <Title level={2} className="text-main mb-2">
          <Link className="text-dark me-3" to="/agent-account-managers">
            <ArrowLeftOutlined />
          </Link>
          Add Agent Account Manager
        </Title>
        <Form
            form={form}
            className="wrapper-content"
            onFinish={onFinish}
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 14 }}
        >
          <div className="row">
            <div className="col-lg-6">
              <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                  label="Name (Arabic)"
                  name="name_ar"
                  rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                  label="Phone Number"
                  name="phone_number"
                  // rules={[{ required: true, message: "This field is required!" }]}
              >
                <IntlTelInput
                    ref={intlTelInputRef}
                    preferredCountries={['sa', 'eg']}
                    separateDialCode={true}
                    utilsScript={"https://cdn.jsdelivr.net/npm/intl-tel-input@17/build/js/utils.js"}
                />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
          </div>
          <Form.Item name="country_code" hidden>
            <Input type="hidden" />
          </Form.Item>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <Form.Item
                  wrapperCol={{ offset: 1, span: 16 }}
              >
                <Button
                    loading={loading}
                    size="large"
                    block
                    type="primary"
                    htmlType="submit"
                >
                  {loading ? "Adding..." : "Add"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
  );
};

export default AddAgentAccountManager;
