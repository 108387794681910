import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Spin, Form, Switch, notification, Select } from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EditUnitType = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});

    useEffect(() => {
        get(`unit-types/${id}`).then((res) => {
            setInputsValue(res.data.data);
            setFromLoading(true);
        });
    }, [id]);


    const [dataPackages, setDataPackages] = useState([]);
    const [optionPackages, setOptionPackages] = useState([]);

    useEffect(() => {
        setOptionPackages(
            dataPackages.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataPackages]);

    const [dataUnitType, setDataUnitType] = useState([]);
    const [optionUnitType, setOptionUnitType] = useState([]);

    useEffect(() => {
        setOptionUnitType(
            dataUnitType.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataUnitType]);

    useEffect(() => {
        get("get-all-packages").then((res) => {
            setDataPackages(res.data);
        });
        get("get-all-unit-types").then((res) => {
            setDataUnitType(res.data);
        });
    }, []);

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);
        put(`unit-types/${id}`, {
            ...values,
            'parent_id': values.parent_id == undefined ? null : values.parent_id
        })
            .then((res) => {
                notification.success({ message: "Modified successfully" });
                setLoading(false);
                navigate("/unit-types");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/unit-types">
                    <ArrowLeftOutlined />
                </Link>
                Edit Unit Type
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        name_ar: inputsValue.name_ar,
                        name_en: inputsValue.name_en,
                        order: inputsValue.order,
                        is_paid: inputsValue.is_paid,
                        is_soon: inputsValue.is_soon,
                        is_active: inputsValue.is_active,
                        is_estimated: inputsValue.is_estimated,
                        package_ids: inputsValue.packages_ids,
                        parent_id: inputsValue.parent && inputsValue.parent.id,
                    }}
                    autoComplete="off"
                >
                    <div className="row">
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Name Ar"
                                name="name_ar"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Name En "
                                name="name_en"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Order "
                                name="order"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input type="number" placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Subscription"
                                name="package_ids"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    options={optionPackages}
                                />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Parent Unit Type"
                                name="parent_id"
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    options={optionUnitType}
                                />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Paid " name="is_paid">
                                <Switch defaultChecked={inputsValue.is_paid} />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Soon " name="is_soon">
                                <Switch defaultChecked={inputsValue.is_soon} />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Estimated " name="is_estimated">
                                <Switch defaultChecked={inputsValue.is_estimated} />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Active " name="is_active">
                                <Switch defaultChecked={inputsValue.is_active} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading ? "Updating..." : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default EditUnitType;
