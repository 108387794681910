import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Input,
    Typography,
    Button,
    Spin,
    Form,
    Switch,
    Select,
    notification,
} from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { GoogleMap, PolygonF, useLoadScript } from "@react-google-maps/api";
import { DefaultLatLng, GoogleMapLibrary, GoogleMapsAPI } from "../../../api/client-config";

import './styles.css';

const { Title } = Typography;

const EditNeighborhood = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});

    const [loading, setLoading] = useState(false);

    const [mapZoomLevel, setMapZoomLevel] = useState(12);

    const [mapPosition, setMapPosition] = useState(DefaultLatLng);

    const [path, setPath] = useState([]);

    const [dataCities, setDataCities] = useState([]);

    const [optionCities, setOptionCities] = useState([]);


    // Define refs for Polygon instance and listeners
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);

    useEffect(() => {
        get(`neighborhoods/${id}`).then((res) => {
            setInputsValue(res.data.data);
            if (res.data.data.center !== null) {
                setMapPosition({
                    lat: res.data.data.center.lat,
                    lng: res.data.data.center.lng
                })
                setMapZoomLevel(14)

            } else {
                setMapPosition({
                    lat: res.data.data.city.map_center.coordinates[1],
                    lng: res.data.data.city.map_center.coordinates[0]
                })
            }

            let polygon = [];
            res.data.data.boundaries.coordinates[0].map((point) => {
                polygon = [
                    ...polygon,
                    {
                        lat: point[1],
                        lng: point[0]
                    }
                ];
            })
            setPath(polygon);
            setFromLoading(true);
        });
        get("get-all-cities").then((res) => {
            setDataCities(res.data);
        });
    }, [id]);

    const { isLoaded } = useLoadScript({
        id: "map",
        googleMapsApiKey: GoogleMapsAPI,
        libraries: GoogleMapLibrary
    });

    // Call setPath with new edited path
    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map(latLng => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            setPath(nextPath);
        }

    }, [setPath]);

    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
        polygon => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );

    const handleOnPolygonCompleted = () => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map(latLng => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            setPath(nextPath);
        }
    };

    useEffect(() => {
        setOptionCities(
            dataCities.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataCities]);

    const changeMapCenter = (city_id) => {
        const city = dataCities.filter(item => item.id === city_id);
        if (city[0] !== null) {
            setMapPosition({
                lat: city[0].map_center.coordinates[1],
                lng: city[0].map_center.coordinates[0],
            })
        }
    }

    const getPolygonCenter = (polygonRef) => {
        var bounds = new window.google.maps.LatLngBounds();

        polygonRef.current.getPath().getArray().map(latLng => {
            bounds.extend(latLng);
        });

        return bounds.getCenter();
    };


    const [dataPackages, setDataPackages] = useState([]);
    const [optionPackages, setOptionPackages] = useState([]);

    useEffect(() => {
        setOptionPackages(
            dataPackages.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataPackages]);

    useEffect(() => {
        get("get-all-packages").then((res) => {
            setDataPackages(res.data);
        });
    }, []);

    const onFinish = (values) => {
        setLoading(true);
        put(`neighborhoods/${id}`, {
            ...values,
            "package_id": values.package_id == undefined ? null : values.package_id,
            'boundaries': path,
            'polygon_center': getPolygonCenter(polygonRef)
        }).then((res) => {
            notification.success({ message: "Modified successfully" });
            setLoading(false);
            navigate("/neighborhoods");
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };


    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/neighborhoods">
                    <ArrowLeftOutlined />
                </Link>
                Edit Neighborhood
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        name_ar: inputsValue.name_ar,
                        name_en: inputsValue.name_en,
                        is_active: inputsValue.is_active,
                        city_id: inputsValue.city.id,
                        package_id: inputsValue.package_id,
                    }}
                    autoComplete="off"
                >
                    <div className="row">
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Name Ar"
                                name="name_ar"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Name En "
                                name="name_en"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="city" name="city_id">
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    onChange={changeMapCenter}
                                    options={optionCities}
                                />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Subscription"
                                name="package_id"
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    options={optionPackages}
                                />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Active " name="is_active">
                                <Switch defaultChecked={inputsValue.is_active} />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-12">
                            <Form.Item label="Map" className="mb-0">

                            </Form.Item>
                            {isLoaded && (
                                <>
                                    <GoogleMap
                                        mapContainerStyle={{
                                            width: "100%",
                                            height: "560px"
                                        }}
                                        center={mapPosition}
                                        zoom={mapZoomLevel}
                                    >
                                        <PolygonF
                                            onLoad={onLoad}
                                            editable
                                            path={path}
                                            onMouseUp={onEdit}
                                            onEdit={handleOnPolygonCompleted}
                                            onFinish={handleOnPolygonCompleted}
                                        />
                                    </GoogleMap>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="row mt-5 pt-2">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading ? "Updating..." : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default EditNeighborhood;
