import React, { useEffect, useState } from "react";
import { Button, Typography, Form, Select, Input, Tag, notification } from "antd";
import { del, get } from "../../../api/axios";
import { Link } from "react-router-dom";
import { Popconfirm } from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import { checkPermission } from "../../control-sections/auth/ProtectedRoutes";
import { onChangeTableHelper } from "../../control-sections/helper/TableHelpers";
import TableComponent from "../../control-sections/helper/TableComponent";

const AgentTeam = () => {
    const { Title } = Typography;
    const [form] = Form.useForm();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);

    const [total, setTotal] = useState(1);

    const columns = [
        {
            title: " Id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "Leader",
            render: (data) => {
                return data.leader ? <Link to={`/agent-users/edit/${data.leader.id}`}>{data.leader.name}</Link> : "No Leader";
            },
        },
        {
            title: "Phone number",
            render: (data) => {
                return data.leader ? data.leader.full_phone_number : "No Leader";
            },
        },
        {
            title: "# of agents",
            dataIndex: "number_of_agents",
            sorter: (a, b) => a.number_of_agents - b.number_of_agents,
        },
        {
            title: '# of campaigns',
            dataIndex: 'number_of_campaigns',
            sorter: (a, b) => a.number_of_campaigns - b.number_of_campaigns,
        },
        {
            title: '# of leads',
            dataIndex: 'number_of_leads',
            sorter: (a, b) => a.number_of_leads - b.number_of_leads,
        },
        {
            title: '# of reservations',
            dataIndex: 'number_of_reservations',
            sorter: (a, b) => a.number_of_reservations - b.number_of_reservations,
        },
        {
            title: "code",
            dataIndex: "code",
            sorter: (a, b) => a.code - b.code,
        },
    ];

    useEffect(() => {
        get("agent-teams?limit=50&sort_by=id&order=asc&page=1").then((res) => {
            setData(res.data.data);
            setTotal(res.data.meta.total);
            setLoading(false);
        });
    }, []);

    const [filterData, setFilterData] = useState({
        filter_key: "",
        filter_value: "",
    });

    const [sorter, setSorter] = useState({
        sort_by: "",
        order: "",
    });

    const handleFilterKey = (e) => {
        setFilterData({ ...filterData, filter_key: e });
    };

    const handleFilterVal = (e) => {
        setFilterData({ ...filterData, [e.target.id]: e.target.value });
    };

    const onFilter = (values) => {
        setLoadingSearch(true);


        get(
            `agent-teams?filter_key=${filterData.filter_key}&filter_value=${filterData.filter_value
            }${sorter.sort_by !== "" && sorter.sort_by !== undefined ? `&sort_by=${sorter.sort_by}` : ""}${sorter.order !== "" ? `&order=${sorter.order}` : ""
            }`
        )
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoadingSearch(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoadingSearch(false);
            });
    };

    const clearSearch = () => {
        form.resetFields();
        setFilterData({
            filter_key: "",
            filter_value: "",
        });
        get("agent-teams").then((res) => {
            setData(res.data.data);
            setTotal(res.data.meta.total);
        });
    };

    const onChangeTable = (pagination, filters, sorter) => {
        setLoading(true);
        setSorter({
            ...sorter,
            sort_by: sorter.field,
            order: sorter.order === "descend" ? "desc" : "asc",
        });

        onChangeTableHelper('agent-teams', pagination, filterData, sorter)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoading(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-6">
                            <Title level={2} className="text-main mb-2">
                                Agent Trainings
                            </Title>
                        </div>
                        {checkPermission("create-agent-team") ? (
                            <div className="col-lg-3 offset-lg-3 text-end">
                                <Button type="primary" className="p-0" size="large">
                                    <Link
                                        className="text-decoration-none h-100 flex-center py-2 px-4"
                                        to="/agent-teams/add"
                                    >
                                        Add Agent Training
                                    </Link>
                                </Button>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>

            <div className="grid-margin stretch-card wrapper-content">
                <Form onFinish={onFilter} layout="vertical" form={form}>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <Form.Item
                                label="Search By"
                                name="filter_key"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Select
                                    className="w-100"
                                    showSearch
                                    placeholder="select test "
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    onChange={handleFilterKey}
                                    options={[
                                        {
                                            value: "name_ar",
                                            label: "Name Ar",
                                        },
                                        {
                                            value: "name_en",
                                            label: "Name En",
                                        },
                                        {
                                            value: "id",
                                            label: "Id",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Item
                                label="Search"
                                name="filter_value"
                                onChange={handleFilterVal}
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type to search..." />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-2 d-flex align-items-end">
                            <Form.Item className="w-100">
                                <div className="row">
                                    <div className="col-8">
                                        <Button
                                            block
                                            loading={loadingSearch}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {loadingSearch ? "Searching" : "Search"}
                                        </Button>
                                    </div>
                                    <div className="col-4">
                                        <Button
                                            onClick={clearSearch}
                                            className="flex-center"
                                            danger
                                            type="primary"
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
                <TableComponent onChangeTable={onChangeTable} loading={loading} columns={columns} data={data} total={total} />
            </div>
        </div>
    );
};

export default AgentTeam;
