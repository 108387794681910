import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Spin,
  Form,
  Switch,
  notification, Upload, Modal, Image,
} from "antd";
import { get, postFromData, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import {ArrowLeftOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import { GoogleMap, MarkerF, Autocomplete, useLoadScript } from "@react-google-maps/api";
import { getGeocode } from "use-places-autocomplete";
import { GoogleMapsAPI, DefaultLatLng, GoogleMapLibrary } from "../../../api/client-config";
import 'react-quill/dist/quill.snow.css';
const { Title } = Typography;
const EditAgentProject = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [fromLoading, setFromLoading] = useState(false);
  const [inputsValue, setInputsValue] = useState({});
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mapPosition, setMapPosition] = useState(DefaultLatLng);
  const [markerPosition, setMarkerPosition] = useState(DefaultLatLng);
  const [previewImage, setPreviewImage] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [imageUrls, setImageUrls] = useState([]);
  const [images, setImages] = useState([]);
  const [mapRef, setMapRef] = useState(null);

  const [acRef, setAcRef] = useState(
      null
  );
  const uploadButton = (
      <>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>
          Upload
        </div>
      </>
  );
  const [currentAddress, setCurrentAddress] = useState("");

  useEffect(() => {
    get(`agent-projects/${id}`).then((res) => {
      setInputsValue(res.data);
      setMapPosition({
        lat: parseFloat(res.data.latitude),
        lng: parseFloat(res.data.longitude)
      });
      setMarkerPosition({
        lat: parseFloat(res.data.latitude),
        lng: parseFloat(res.data.longitude)
      });

      let list = [];
      let preview = [];
      res.data.photos.forEach((photo, index) => {
        list.push({
          uid: '#'+index,
          name: photo.file,
          status: 'done',
          url: photo.path,
        });

        preview.push(photo.file);
      });
        setFileList(list);
        setFileArList(res.data.file_ar ? [{ name: res.data.file_ar.file ,url: res.data.file_ar.path }] : []);
        setFileEnList(res.data.file_en ? [{ name: res.data.file_en.file ,url: res.data.file_en.path }] : []);
        setImageUrls(preview);
        setImages(preview);
      setFromLoading(true);
    });
  }, [id]);
  const handleBeforeUpload = (e) => {
    setLoading('uploading');
    postFromData('/upload-image', { 'image': e, 'store_path': 'agent-projects', 'old_path': inputsValue.image_path }).then(res => {
      setInputsValue({ ...inputsValue, image_path: res.data });
      setImages([...images, res.data]);
      setLoading('');
    })
      .catch((err) => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      })
  };
  const handlePreview = async (file) => {
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = ({ fileList }) => {
    setFileList(fileList);
    // Filter imageUrls to include only URLs of images that are still in fileList
    const currentUrls = fileList.map((file) => file.url).filter(Boolean);
    const currentImages = fileList.map((file) => file.name).filter(Boolean);
    setImageUrls(currentUrls);  // Update imageUrls to reflect only current files
    setImages(currentImages);
    console.log('change',fileList, imageUrls, images);
  };
  const handleOnLoad = (map) => {
    setMapRef(map);
  };

  const handleOnLoadAC = (autocomplete) => {
    setAcRef(autocomplete);
  };

  const handleOnCenterChanged = () => {
    if (mapRef) {
      const centerMap = mapRef.getCenter();
      const centerLat = centerMap?.lat();
      const centerLng = centerMap?.lng();
      if (centerLat && centerLng) {
        setMarkerPosition({
          lat: centerLat,
          lng: centerLng
        });
      }
    }
  };

  const handleOnDragEnd = async () => {
    if (!mapRef) return;
    const centerMap = mapRef.getCenter();
    const res = await getGeocode({ centerMap });
    const streetAddress = res.find(({ types }) =>
      types.includes("street_address")
    );
    setCurrentAddress(
      streetAddress?.formatted_address ?? res[0].formatted_address
    );
  };

  const handleOnACSelected = () => {
    if (acRef) {
      const selectedPlace = acRef.getPlace();
      const selectedLat = selectedPlace.geometry?.location?.lat();
      const selectedLng = selectedPlace.geometry?.location?.lng();
      if (selectedLat && selectedLng) {
        setMapPosition({
          lat: selectedLat,
          lng: selectedLng
        });
      }
    }
  };

  const { isLoaded } = useLoadScript({
    id: "map",
    googleMapsApiKey: GoogleMapsAPI,
    libraries: GoogleMapLibrary
  });

  // Handle Arabic file upload change
  const handleChangeAr = ({ fileList }) => {
    setFileArList(fileList);
  };

  // Handle English file upload change
  const handleChangeEn = ({ fileList }) => {
    setFileEnList(fileList);
  };

  const handleBeforeUploadAr = (e) => {
    postFromData('/upload-file', { 'file': e, 'store_path': 'agent-projects' }).then(res => {
      setInputsValue({ ...inputsValue, file_ar: res.data });
    }).catch((err) => {
      notification.error({
        message: "Error!",
        description: err.response.data.message,
      });
      setLoading(false);
    });
  };
  const handleBeforeUploadEn = (e) => {
    postFromData('/upload-file', { 'file': e, 'store_path': 'agent-projects' }).then(res => {
      setInputsValue({ ...inputsValue, file_en: res.data })
    }).catch((err) => {
      notification.error({
        message: "Error!",
        description: err.response.data.message,
      });
      setLoading(false);
    });
  };

  const [fileArList, setFileArList] = useState(inputsValue.file_ar ? [{ url: inputsValue.file_ar.file }] : []);
  const [fileEnList, setFileEnList] = useState(inputsValue.file_en ? [{ url: inputsValue.file_en.file }] : []);

  const onFinish = (values) => {
    setLoading(true);
    console.log(values, inputsValue, typeof inputsValue.file_en);
    if (inputsValue.file_en instanceof Object) {
      inputsValue.file_en = inputsValue.file_en.file;
      console.log('file_en', inputsValue.file_en.file);
    }
    if (inputsValue.file_ar instanceof Object) {
        inputsValue.file_ar = inputsValue.file_ar.file;
        console.log('file_ar', inputsValue.file_ar);
    }

    put(`agent-projects/${id}`, {
      ...values,
      file_en: inputsValue.file_en,
      file_ar: inputsValue.file_ar,
      is_active: values.is_active ? 1 : 0,
      photos: images,
      'latitude': markerPosition.lat,
      'longitude': markerPosition.lng,
    }).then((res) => {
      notification.success({ message: "Modified successfully" });
      setLoading(false);
      navigate("/agent-projects");
    })
      .catch((err) => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-projects">
          <ArrowLeftOutlined />
        </Link>
        Edit Agent Project
      </Title>
      {fromLoading ? (
        <Form
          className="wrapper-content"
          onFinish={onFinish}
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 15,
          }}
          initialValues={{
            name_ar: inputsValue.name_ar,
            name_en: inputsValue.name_en,
            is_active: inputsValue.is_active,
            developer_en: inputsValue.developer_en,
            developer_ar: inputsValue.developer_ar,
            location_ar: inputsValue.location_ar,
            location_en: inputsValue.location_en,
            file_ar: inputsValue.file_ar.file,
            file_en: inputsValue.file_en.file,
            price: inputsValue.price,
            order: inputsValue.order,
            latitude: inputsValue.latitude,
            longitude: inputsValue.longitude,
            photos: inputsValue.photos,
          }}
          autoComplete="off"
        >
          <div className="row">
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Name Ar"
                  name="name_ar"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
              >
                <Input placeholder="Type..."/>
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Name En "
                  name="name_en"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
              >
                <Input placeholder="Type..."/>
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Developer Ar"
                  name="developer_ar"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
              >
                <Input placeholder="Type..."/>
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Developer En "
                  name="developer_en"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
              >
                <Input placeholder="Type..."/>
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Location Ar"
                  name="location_ar"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
              >
                <Input placeholder="Type..."/>
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Location En "
                  name="location_en"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
              >
                <Input placeholder="Type..."/>
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Price"
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    }
                  ]}
              >
                <Input type="number"
                       placeholder="Type..."/>
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Order"
                  name="order"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    }
                  ]}
              >
                <Input type="number"
                       placeholder="Type..."/>
              </Form.Item>
            </div>

            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="File Ar"
                  name="file_ar"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    }
                  ]}
              >
                <Upload
                    fileList={fileArList}
                    onChange={handleChangeAr}
                    beforeUpload={handleBeforeUploadAr}
                    listType="text"
                    maxCount={1}
                    onRemove={() => setFileArList([])} // Clear file on remove
                >
                  {fileArList.length === 0 && (
                      <Button icon={<UploadOutlined />}>Click to upload Arabic file</Button>
                  )}
                </Upload>
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="File En"
                  name="file_en"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    }
                  ]}
              >
                <Upload
                    fileList={fileEnList}
                    onChange={handleChangeEn}
                    beforeUpload={handleBeforeUploadEn}
                    listType="text"
                    maxCount={1}
                    onRemove={() => setFileEnList([])} // Clear file on remove
                >
                  {fileEnList.length === 0 && (
                      <Button icon={<UploadOutlined />}>Click to upload English file</Button>
                  )}
                </Upload>
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item label="Active " name="is_active">
                <Switch defaultChecked={inputsValue.is_active}/>
              </Form.Item>
            </div>
            <div className="col-lg-12">
              <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  beforeUpload={handleBeforeUpload}
              >
                {fileList.length >= 12 ? null : uploadButton}
              </Upload>
              <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={() => setPreviewOpen(false)}
              >
                <Image
                    alt="example"
                    style={{width: '100%'}}
                    src={previewImage}
                />
              </Modal>
            </div>
            <div className="col-lg-12">
              <Form.Item label="Map Center" className="mb-0">

              </Form.Item>
              {isLoaded && (
                  <>
                    <Autocomplete
                        onLoad={handleOnLoadAC}
                        onPlaceChanged={handleOnACSelected}
                    >
                      <input
                          type="text"
                          style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `100%`,
                            height: `50px`,
                            padding: `0 12px`,
                            marginBottom: `15px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`
                          }}
                      />
                    </Autocomplete>
                    <p>{currentAddress}</p>
                    <GoogleMap
                        onLoad={handleOnLoad}
                        mapContainerStyle={{
                          width: "100%",
                          height: "400px"
                        }}
                        center={mapPosition}
                        zoom={13}
                        onCenterChanged={handleOnCenterChanged}
                        onDragEnd={handleOnDragEnd}
                    >
                      <MarkerF position={markerPosition}/>
                    </GoogleMap>
                  </>
              )}
            </div>

          </div>
          <div className="row mt-5 pt-2">
            <div className="col-lg-4 offset-lg-4">
              <Form.Item
                  wrapperCol={{
                    offset: 1,
                    span: 16,
                  }}
              >
                <Button
                    loading={loading}
                    size="large"
                    block
                    type="primary"
                    htmlType="submit"
                >
                  {loading ? "Updating..." : "Update"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
          <div className="text-center">
            <Spin size="large"/>
          </div>
      )}
    </div>
  );
};

export default EditAgentProject;
