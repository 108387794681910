import React, { useEffect, useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { post } from "../../../api/axios";
import {  useNavigate } from "react-router-dom";

const ForgetPassword = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [loginData, setLoginData] = useState({ email: ""});

    const handleChange = (e) => {
        setLoginData({ ...loginData, [e.target.id]: e.target.value });
    };


    useEffect(() => {
        const loggedInAdmin = localStorage.getItem("loggedInAdmin");
        if (loggedInAdmin) {
            window.location.href = "/";
        }
    }, []);

    const onFinish = (values) => {
        setLoading(true);

        post("auth/forget-password", values)
            .then((res) => {
                notification.success({message:"sent succesfully"});
                navigate("/otp");
                localStorage.setItem('emailOtp', values.email)
            }).catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                    duration: 5000
                });
                setLoading(false);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

   

    return (
        <div className="login-section">
            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-7 col-lg-5">
                            <div className="login-wrap p-4 p-md-5">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <UserOutlined />
                                </div>
                                <h3 className="text-center mb-4"> Forget Password </h3>
                                <Form
                                    className="login-form"
                                    layout="vertical"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: "This field is required!",
                                            },
                                        ]}
                                        onChange={handleChange}
                                    >
                                        <Input size="large" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            className="form-control btn btn-primary rounded submit px-3"
                                            type="primary"
                                            block
                                            htmlType="submit"
                                            loading={loading}
                                        >
                                            {loading ? "Loading..." : "Submit"}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ForgetPassword;
