import { Button, notification } from 'antd';
import React, { useState,useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { post } from '../../../api/axios';
import { useNavigate } from "react-router-dom";


const Otp = () => {

    useEffect(() => {
        const loggedInAdmin = localStorage.getItem("loggedInAdmin");
        if (loggedInAdmin) {
            window.location.href = "/";
        }
    }, []);


    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = () => {
        if (otp.length === 6) {
            setLoading(true)
            post('auth/validate-otp', { 'otp_code': otp }).then(res => {
                navigate("/reset-password");
            })
            
        } else{
            notification.error({message:"Error!", description:'otp is invalid'})
        }
    }


    return (
        <div>
            {/* <OtpInput value={otp} onChange={handleChange} numInputs={6} separator={<span>-</span>} /> */}


            {/* <button onClick={handleSubmit}> Submit </button> */}
            <div className="login-section">
                <section className="ftco-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-7 col-lg-5">
                                <div className="login-wrap p-4 p-md-5">

                                    <h3 className="text-center mb-4"> OTP Code </h3>
                                    {/* <Form
                                        className="login-form"
                                        layout="vertical"
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                    >
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "This field is required!",
                                                },
                                            ]}
                                            onChange={handleChange}
                                        >
                                            <Input size="large" />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                className="form-control btn btn-primary rounded submit px-3"
                                                type="primary"
                                                block
                                                htmlType="submit"
                                                loading={loading}
                                            >
                                                {loading ? "Loading..." : "Submit"}
                                            </Button>
                                        </Form.Item>
                                    </Form> */}
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={6}
                                        renderSeparator={<span> - </span>}
                                        inputStyle={{
                                            border: "1px solid #CFD3DB",
                                            borderRadius: "8px",
                                            width: "54px",
                                            height: "54px",
                                            fontSize: "12px",
                                            color: "#000",
                                            fontWeight: "400",
                                            caretColor: "blue"
                                        }}
                                        focusStyle={{
                                            border: "1px solid #CFD3DB",
                                            outline: "none"
                                        }}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                    <Button
                                        className="form-control btn btn-primary rounded submit mt-4 px-3"
                                        type="primary"
                                        block
                                        onClick={handleSubmit}
                                        loading={loading}
                                    >
                                        {loading ? "Loading..." : "Submit"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Otp;


