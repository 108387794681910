import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Form, Input, notification, Row, Spin,} from "antd";
import axiosApi, {get, post} from "../../../api/axios";
import {useParams} from "react-router";
import './FantasticForm.css';
import {CheckCircleOutlined} from "@ant-design/icons";
import IntlTelInput from "intl-tel-input/reactWithUtils";
import "intl-tel-input/styles";
import {ar, en} from "intl-tel-input/i18n";
import {useLocation} from "react-router-dom";


const CampaignForm = () => {
  let { lang, id} = useParams();
  const [loading, setLoading] = useState(true);
  const [showAgentName, setShowAgentName] = useState(false);
  const [campaignAgentName, setCampaignAgentName] = useState({});
  const [message, setMessage] = useState(null);
  const [form] = Form.useForm();  // Create a form instance
  const [customMessage, setCustomMessage] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const utmSource = queryParams.get('utm_source');
  const utmMedium = queryParams.get('utm_medium');
  const utmCampaign = queryParams.get('utm_campaign');
  const utmContent = queryParams.get('utm_content');
  const placeholders = {
    en: {
      name: "Enter your name",
      phone: "Enter your phone number",
    },
    ar: {
      name: "ادخل اسمك",
      phone: "ادخل رقم هاتفك",
    },
  };
  const [isValid, setIsValid] = useState(null);
  const [number, setNumber] = useState(null);
  const [country, setCountry] = useState('sa');
  const [errorCode, setErrorCode] = useState(null);
  const [notice, setNotice] = useState(null);
  const [title, setTitle] = useState({
    ar: '',
    en: '',
  });
  const intlTelInputRef = useRef(null);

  useEffect(() => {
    get(`campaigns/form/${id}`).then(res => {
      setShowAgentName(res.data.data.show_agent_name);
      console.log(res.data.title.en);
      setTitle({
        ar: res.data.title.ar,
        en: res.data.title.en,
      });
      if (res.data.data.show_agent_name) {
        setCampaignAgentName(res.data.data.agent ? res.data.data.agent.name : 'AgentName');
      }
      if (res.data.data.message_ar !== '' && res.data.data.message_en !== '') {
        setCustomMessage({
          en: res.data.data.message_en,
          ar: res.data.data.message_ar,
        });
      }
    });
    console.log(utmCampaign, utmContent, utmMedium, utmSource);
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-P43WNJQS');
    `;
    document.head.appendChild(script);

    // Add <noscript> tag to the body
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P43WNJQS"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noscript);

    // Clean up when the component unmounts
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, [id]);
  const onFinish = values => {
    const intlTelInputInstance = intlTelInputRef.current.getInstance();
    let validNumber, selectedCountryDialCode;
    if (isValid) {
      validNumber = intlTelInputInstance.telInput.value;
      selectedCountryDialCode = intlTelInputInstance.getSelectedCountryData().dialCode;

      setNotice(`Valid number: ${number}`);
    } else {
      switch (errorCode) {
        case 0:
          setNotice(lang == 'en' ? "Invalid number" : "رقم غير صحيح");
          break;
        case 1:
          setNotice(lang == 'en' ? "Invalid country code" : "رمز البلد غير صحيح");
          break;
        case 2:
          setNotice(lang == 'en' ? "Too short" : "قصير جدا");
          break;
        case 3:
          setNotice(lang == 'en' ? "Too long" : "طويل جدا");
          break;
        case 4:
          setNotice(lang == 'en' ? "Invalid number" : "رقم غير صحيح");
          break;
        default:
          setNotice(null);
          break;
      }
    }
    console.log(values, number);
    if (isValid) {
      setLoading(false);

      const newValues = {
        ...values,
        'country_code': '+'+selectedCountryDialCode,
        'phone_number': validNumber,
        'campaign_id': id,
        'utm_source': utmSource,
        'utm_medium': utmMedium,
        'utm_campaign': utmCampaign,
        'utm_content': utmContent,
      };

      axiosApi.defaults.headers['lang'] = lang;
      post(`campaigns/form`, newValues)
          .then(res => {
            notification.success({ message: res.message });
            form.resetFields();  // Reset the form only on success

            setLoading(true);
            setMessage(res.message);
          })
          .catch(err => {
            notification.error({
              message: "Error!",
              description: err.response.data.message,
            });
            setLoading(true);
          });
    }
  };

  return (
      <div className="fantastic-form-container" dir={lang == 'en' ? 'ltr' : 'rtl'}>
      <Row justify="center" className="w-100">
        <Col className={"col-md-8 col-xl-6 col-sm-12"}>
          {
            message ? <>
                  <div className="d-flex align-items-center flex-column">
                    <CheckCircleOutlined style={{
                      fontSize: '5em',
                      color: 'green',
                      marginBottom: '20px'
                    }}/>
                    <h2 className="form-title">
                      {message}
                    </h2>
                    <p style={{
                      color: '#056822',
                    }}>
                      {
                        customMessage ? customMessage[lang] : ''
                      }
                    </p>
                  </div>
                </> :
                <>
                  <div className="d-flex flex-column align-items-center">
                    <h2 className="form-title">
                      {
                        lang == 'en' ? title.en : title.ar
                      }
                    </h2>
                    <p style={{
                      color: '#05688c',
                    }}>
                      {
                        showAgentName ? (
                            lang == 'en' ? `We will send your request to the agent ${campaignAgentName} to assist you` : ` سيتم إرسال طلبك إلى المسوق  ${campaignAgentName}  ليقوم بمساعدتك`
                        ) : ''
                      }
                    </p>
                  </div>
                  {loading ? (<>
                    <Form
                        layout="vertical"
                        form={form}  // Attach the form instance to the form
                        onFinish={onFinish}
                        className="fantastic-form"
                    >
                      <Form.Item
                          label={lang == 'en' ? "Name" : "الاسم"}
                          name="name" autoFocus={true}
                          rules={[
                            {
                              required: true,
                              message: lang == 'en' ? 'Your Name is required!' : 'الاسم مطلوب',
                            },
                          ]}
                      >
                        <Input placeholder={placeholders[lang].name} className="form-input"/>
                      </Form.Item>

                      <Form.Item
                          style={{direction: 'ltr  !important'}}
                          label={lang == 'en' ? "Phone number" : "رقم الهاتف"}
                          name="phone_number"
                          help={notice}
                      >
                        <IntlTelInput
                            ref={intlTelInputRef}
                            onChangeNumber={setNumber}
                            onChangeValidity={setIsValid}
                            onChangeErrorCode={setErrorCode}
                            onPhoneNumberChange={setNumber}
                            onPhoneNumberBlur={setNumber}
                            onPhoneNumberFocus={setNumber}
                            preferredCountries={['sa']}
                            separateDialCode={true}
                            onChangeCountry={setCountry}
                            format
                            formatOnInit
                            utilsScript={"libphonenumber.js"}
                            initOptions={{
                              initialCountry: country,
                              i18n: lang == 'en' ? {...en} : {...ar},
                              separateDialCode: true,
                            }}
                        />
                      </Form.Item>

                      <Form.Item>
                        <Button type="primary" htmlType="submit" className="submit-button">
                          {lang == 'en' ? " Submit" : "إرسال"}
                        </Button>
                      </Form.Item>
                    </Form>
                  </>) : (

                      <div className="text-center">
                        <Spin size="large"/>
                      </div>
                  )
                  }
                </>
          }
        </Col>
      </Row>
    </div>
  );
};

export default CampaignForm;
