import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Spin,
  Form,
  Switch,
  notification,
  Select,
} from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const { Title } = Typography;

const EditAgentTraining = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [fromLoading, setFromLoading] = useState(false);

  const [inputsValue, setInputsValue] = useState({});

  useEffect(() => {
    get(`agent-trainings/${id}`).then(res => {
      setInputsValue(res.data);
      setFromLoading(true);
    });
  }, [id]);

  const [loading, setLoading] = useState(false);




  const [dataType, setDataType] = useState([]);
  const [optionType, setOptionType] = useState([]);

  useEffect(() => {
    setOptionType(
      dataType.map((d) => ({
        value: d.id,
        label: d.name_en,
      }))
    );
  }, [dataType]);

  useEffect(() => {
    get("agent-training-categories").then((res) => {
      setDataType(res.data.data);
    });
  }, []);

  const onFinish = values => {
    setLoading(true);
    const newValues = {
      ...values,
    }

    put(`agent-trainings/${id}`, newValues)
      .then(res => {
        notification.success({ message: "Modified successfully" });
        setLoading(false);
        navigate("/agent-trainings");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-trainings">
          <ArrowLeftOutlined />
        </Link>
        Edit Agent Training
      </Title>
      {fromLoading ? (
        <Form
          className="wrapper-content"
          onFinish={onFinish}
          labelCol={{
            span: 9,
          }}
          wrapperCol={{
            span: 14,
          }}
          initialValues={{
            name_ar: inputsValue.name_ar,
            name_en: inputsValue.name_en,
            description_ar: inputsValue.description_ar,
            description_en: inputsValue.description_en,
            youtube_link: inputsValue.youtube_link,
            minutes_duration: inputsValue.minutes_duration,
            agent_training_category_id: inputsValue.agent_training_category_id,
            order: inputsValue.order,
            is_active: inputsValue.is_active,
          }}
        >
          <div className="row">
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Name Ar"
                name="name_ar"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Name En "
                name="name_en"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Description Ar"
                name="description_ar"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <TextArea placeholder='Type...' ></TextArea>
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Description En"
                name="description_en"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <TextArea placeholder="Type..." ></TextArea>
              </Form.Item>
            </div>

            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Youtube link"
                name="youtube_link"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Minutes Duration"
                name="minutes_duration"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Agent Training Category"
                name="agent_training_category_id"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={optionType}
                />
              </Form.Item>
            </div>


            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Order "
                name="order"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input type="number" placeholder="Type..." />
              </Form.Item>
            </div>

            {/* Item */}
            <div className="col-lg-6">
              <Form.Item label="Active " name="is_active">
                <Switch defaultChecked={inputsValue.is_active} />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "Updating..." : "Update"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default EditAgentTraining;
