import React, { useEffect, useState } from "react";
import {
    Input,
    Typography,
    Button,
    Spin,
    Form,
    Switch,
    notification,
    Select,
} from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EditStatisticCategory = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);
    const [inputsValue, setInputsValue] = useState({});
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        get(`statistic-categories/${id}`).then((res) => {
            setInputsValue(res.data.data);
            setFromLoading(true);
        });
    }, [id]);


    const [dataPackages, setDataPackages] = useState([]);
    const [optionPackages, setOptionPackages] = useState([]);

    useEffect(() => {
        setOptionPackages(
            dataPackages.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataPackages]);

    useEffect(() => {
        get("get-all-packages").then((res) => {
            setDataPackages(res.data);
        });
    }, []);
    
    const onFinish = (values) => {
        setLoading(true);
        put(`statistic-categories/${id}`, {
            ...values,
            "package_id": values.package_id == undefined ? null : values.package_id,
        })
        .then((res) => {
            notification.success({message:"Modified successfully"});
            setLoading(false);
            navigate("/statistic-categories");
        })
        .catch((err) => {
            notification.error({
                message: "Error!",
                description: err.response.data.message,
            });
            setLoading(false);
        });
    };


    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/statistic-categories">
                    <ArrowLeftOutlined />
                </Link>
                Edit Statistic Category
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        name_ar: inputsValue.name_ar,
                        name_en: inputsValue.name_en,
                        order: inputsValue.order,
                        is_active: inputsValue.is_active,    
                        is_soon: inputsValue.is_soon,    
                        package_id: inputsValue.package_id,

                    }}
                    autoComplete="off"
                >
                    <div className="row">
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Name Ar"
                                name="name_ar"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Name En "
                                name="name_en"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Order "
                                name="order"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input type="number" placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Subscription"
                                name="package_id"
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    options={optionPackages}
                                />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Active " name="is_active">
                                <Switch defaultChecked={inputsValue.is_active} />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Soon " name="is_soon">
                                <Switch defaultChecked={inputsValue.is_soon} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading ? "Updating..." : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default EditStatisticCategory;
