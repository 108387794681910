import React, {useEffect, useState} from "react";
import {
  Input,
  Typography,
  Button,
  Form,
  notification,
  Select, Card,
} from "antd";
import {get, post} from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const AddAgentUnit = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [optionProject, setOptionProject] = useState([]);
  useEffect(() => {
    get("get-agent-projects").then(res => {
      let projects = [];
      res.data.map(project => {
        projects.push({ label: project.name_en, value: project.id});
      });
      setOptionProject(projects);
    });
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    post(`agent-project-units`, {
      ...values,
    }).then((res) => {
      notification.success({ message: "Saved successfully" });
      setLoading(false);
      navigate("/agent-units");
    })
        .catch((err) => {
          notification.error({
            message: "Error!",
            description: err.response.data.message,
          });
          setLoading(false);
        });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-units">
          <ArrowLeftOutlined />
        </Link>
        Add Unit
      </Title>
      <Form
        className="wrapper-content"
        onFinish={onFinish}
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 15,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="row">
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Number"
                  name="number"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
              >
                <Input placeholder="Type..."/>
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Price"
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    }
                  ]}
              >
                <Input type="number"
                       placeholder="Type..."/>
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Area"
                  name="area"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    }
                  ]}
              >
                <Input type="number"
                       placeholder="Type..."/>
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Project"
                  name="agent_project_id"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
              >
                <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    className="w-100"
                    filterOption={(input, option) =>
                        (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    placeholder="Select"
                    options={optionProject}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  label="Status"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
              >
                <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    className="w-100"
                    filterOption={(input, option) =>
                        (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    placeholder="Select"
                    options={[
                      {label: "Available", value: "available"},
                      {label: "Reserved", value: "reserved"},
                      {label: "Sold", value: "sold"},
                    ]}
                />
              </Form.Item>
            </div>
              {/* Item */}
              <div className="col-lg-6">
                  <Form.Item
                      label="Order"
                      name="order"
                      rules={[
                          {
                              required: true,
                              message: "This field is required!",
                          },
                      ]}
                  >
                      <Input type="number" />
                  </Form.Item>
              </div>
            {/* Item */}
            <div className="col-lg-12">
              <Card
                  size="small"
              >
                {/* Nest Form.List */}
                <Form.Item label="Fields">
                  <Form.List name='custom_fields'>
                    {(subFields, subOpt) => (
                        <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: 16,
                            }}
                        >
                          {subFields.map((subField) => (
                              <div className="row" key={subField.key}>
                                <div className="col-lg-6">
                                  <Form.Item
                                      wrapperCol={{
                                        offset: 1,
                                        span: 18,
                                      }}
                                      rules={[
                                        {
                                          required: true,
                                          message: "This field is required!",
                                        }
                                      ]}
                                      label='Name Ar' name={[subField.name, 'name_ar']}>
                                    <Input placeholder="Type..."/>
                                  </Form.Item>

                                </div>
                                <div className="col-lg-6">
                                  <Form.Item
                                      wrapperCol={{
                                        offset: 1,
                                        span: 18,
                                      }}
                                      rules={[
                                        {
                                          required: true,
                                          message: "This field is required!",
                                        }
                                      ]}
                                      label='Value Ar' name={[subField.name, 'value_ar']}>
                                    <Input placeholder="Type..."/>
                                  </Form.Item>

                                </div>
                                <div className="col-lg-6">
                                  <Form.Item
                                      wrapperCol={{
                                        offset: 1,
                                        span: 18,
                                      }}
                                      rules={[
                                        {
                                          required: true,
                                          message: "This field is required!",
                                        }
                                      ]}
                                      label='Name En' name={[subField.name, 'name_en']}>
                                    <Input placeholder="Type..."/>
                                  </Form.Item>
                                </div>
                                <div className="col-lg-6">
                                  <Form.Item
                                      wrapperCol={{
                                        offset: 1,
                                        span: 18,
                                      }}
                                      rules={[
                                        {
                                          required: true,
                                          message: "This field is required!",
                                        }
                                      ]}
                                      label='Value En' name={[subField.name, 'value_en']}>
                                    <Input placeholder="Type..."/>
                                  </Form.Item>

                                </div>
                                <div className="col-lg-4">
                                  <Form.Item
                                      wrapperCol={{
                                        offset: 2,
                                        span: 16,
                                      }}
                                      rules={[
                                        {
                                          required: true,
                                          message: "This field is required!",
                                        }
                                      ]}
                                      label='Order' name={[subField.name, 'order']}>
                                    <Input className="ms-3" type="number" placeholder="Type..."/>
                                  </Form.Item>
                                </div>
                              </div>
                          ))}
                          <Button type="dashed" onClick={() => subOpt.add()} block>
                            + Add New Field
                          </Button>
                        </div>
                    )}
                  </Form.List>
                </Form.Item>
              </Card>
            </div>
          </div>
        </div>
        <div className="row mt-5 pt-2">
          <div className="col-lg-4 offset-lg-4">
            <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
            >
              <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddAgentUnit;
