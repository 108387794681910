import { notification } from 'antd';
import { get } from '../../../api/axios';
export const PaginationHelper = (path, pageNumber, filterData, sorter) => {
    return get(`${path}?page=${pageNumber}${filterData.filter_key !== '' ? `&filter_key=${filterData.filter_key}` : ''}${filterData.filter_value !== '' ? `&filter_value=${filterData.filter_value}` : ''}${sorter.sort_by !== "" ? `&sort_by=${sorter.sort_by}` : ""}${sorter.order !== "" ? `&order=${sorter.order}` : ""}`)
}

export const onChangeTableHelper = (paht, pagination, filterData, sorter) => {
    return get(
        `${paht}?page=${pagination.current}&limit=${pagination.pageSize} ${filterData.filter_key !== "" && filterData.filter_value !== ""
            ? `&filter_key=${filterData.filter_key}&filter_value=${filterData.filter_value}`
            : ""
        }${sorter.field
            ? `&sort_by=${sorter.field}&order=${sorter.order === "descend" ? "desc" : "asc"
            }`
            : ""
        }
        `
    )
    .catch((err) => {
        notification.error({
            message: "Error!",
            description: err.response.data.message,
        });
    })
}