import React, { useEffect, useState } from "react";
import { Table, Button, Form, Select, Input, Tabs, Tag, DatePicker, Modal, notification } from "antd";
import { del, get, post } from "../../../api/axios";
import { Link } from "react-router-dom";
import { Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { checkPermission } from "../../control-sections/auth/ProtectedRoutes";
import TabPane from "antd/es/tabs/TabPane";
import TextArea from "antd/es/input/TextArea";

const Users = () => {
    // const { Title } = Typography;
    const [form] = Form.useForm();
    const [NotificationForm] = Form.useForm();
    const [BulkNotificationForm] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [userType, setUserType] = useState({
        type: "approved"
    });
    const [selectedUsers, setSelectedUsers] = useState({
        users: []
    });
    const [total, setTotal] = useState(1);
    const columns = [
        {
            title: " Id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: " Name",
            dataIndex: "name",
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: "Phone Number",
            dataIndex: "full_phone_number",
            sorter: (a, b) => a.phone_number - b.phone_number,
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: (a, b) => a.email - b.email,
        },
        {
            title: "Register Date",
            dataIndex: "created_at",
            sorter: (a, b) => a.created_at - b.created_at,
        },
        {
            title: "Active",
            render: ({ is_active }) => (
                <>
                    <Tag color={`${is_active ? "success" : "error"}`}>
                        {String(is_active)}
                    </Tag>
                </>
            ),
        },
    ];

    if (checkPermission("update-user") || checkPermission("delete-user")) {
        columns.push({
            title: "Actions",
            dataIndex: "id",
            render: (id) => (
                <div>
                    {checkPermission("update-user") ? (
                        <Button className="btn btn-outline-warning btn-edit btn-sm p-0 me-3">
                            <Link
                                className="color-inherit text-decoration-none py-1 px-2 d-block"
                                to={`/users/edit/${id}`}
                            >
                                <EditOutlined />
                            </Link>
                        </Button>
                    ) : (
                        <></>
                    )}
                    {checkPermission("delete-user") ? (
                        <Popconfirm
                            title="Delete item"
                            description="Are you sure you want to delete this item?"
                            onConfirm={() => confirm(id)}
                            onCancel={cancel}
                            okText="OK"
                            cancelText="Cancel"
                        >
                            <Button danger className="flex-center d-inline-flex">
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    ) : (
                        <></>
                    )}
                </div>
            ),
        });
    }

    useEffect(() => {
        get(`users?user_type=${userType.type}`).then((res) => {
            setData(res.data.data);
            setTotal(res.data.meta.total);
            setLoading(false);
        });
    }, [userType]);

    const handleDelete = (id) => {
        del(`users/${id}`)
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
        let dateFilter = data.filter((item) => item.id !== id);
        setData(dateFilter);
    };

    const confirm = (id) => {
        handleDelete(id);
        notification.success({ message: "It was successful" });
    };

    const cancel = (e) => {
        notification.info({ message: "The order has been cancelled" });
    };

    // const handlePagination = (pageNumber) => {
    //     setLoading(true);

    //     get(`users?user_type=${userType.type}&page=${pageNumber}${filterData.filter_key !== '' ? `&filter_key=${filterData.filter_key}` : ''}${filterData.filter_value !== '' ? `&filter_value=${filterData.filter_value}` : ''} 
    //     ${sorter.sort_by !== "" ? `&sort_by=${sorter.sort_by}` : ""}${sorter.order !== "" ? `&order=${sorter.order}` : ""
    //         }`).then((res) => {
    //             setData(res.data.data);
    //             setTotal(res.data.meta.total);
    //             setLoading(false);
    //         })
    //         .catch((err) => {
    //             notification.error({
    //                 message: "Error!",
    //                 description: err.response.data.message,
    //             });
    //             setLoading(false);
    //         });
    // };

    const [filterData, setFilterData] = useState({
        filter_key: "",
        filter_value: "",
    });


    const [sorter, setSorter] = useState({
        sort_by: "",
        order: "",
    });

    const handleUserType = (type) => {
        setUserType({
            type: type ?? 'approved'
        });
        setSelectedUsers({
            users: []
        })
    };

    const handleFilterKey = (e) => {
        setFilterData({ ...filterData, filter_key: e });
    };

    const handleFilterVal = (e) => {
        setFilterData({ ...filterData, [e.target.id]: e.target.value });
    };

    const onFilter = (values) => {
        setLoadingSearch(true);

        get(
            `users?user_type=${userType.type}&
                filter_key=${filterData.filter_key}&filter_value=${filterData.filter_value}
                ${sorter.sort_by !== "" && sorter.sort_by !== undefined ? `&sort_by=${sorter.sort_by}` : ""}${sorter.order !== "" ? `&order=${sorter.order}` : ""
            }`
        )
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoadingSearch(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoadingSearch(false);
            });
    };

    const clearSearch = () => {
        form.resetFields();
        setFilterData({
            filter_key: "",
            filter_value: "",
            filter_type: "approved"
        });
        get(`users?user_type=${userType.type}`).then((res) => {
            setData(res.data.data);
            setTotal(res.data.meta.total);
        });
    };

    const onChangeTable = (pagination, filters, sorter) => {
        setLoading(true);

        setSorter({
            ...sorter,
            sort_by: sorter.field,
            order: sorter.order === "descend" ? "desc" : "asc",
        });
        get(
            `users?page=${pagination.current}&limit=${pagination.pageSize}&user_type=${userType.type}&
            ${(filterData.filter_key !== '' && filterData.filter_value !== '') ?
                `filter_key=${filterData.filter_key}` :
                ''
            }${(filterData.filter_value && filterData.filter_value !== '') !== '' ?
                `&filter_value=${filterData.filter_value}&`
                : ''
            }sort_by=${sorter.field !== "" && sorter.field !== undefined ? sorter.field : "id"}&order=${sorter.order === "descend" ? "desc" : "asc"}`
        )
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoading(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    const bulkActivateUsers = () => {
        if (selectedUsers.users.length > 0) {
            post(`users-bulk-action`, {
                users: selectedUsers.users,
                action: "is_active",
                "value": true,
            }).then((res) => {
                notification.success({ message: "Users activated successfully" });

                setTimeout(() => {
                    window.location.reload();
                }, 3000)
            })
                .catch((err) => {
                    notification.error({
                        message: "Error!",
                        description: err.response.data.message,
                    });
                    setLoading(false);
                });
        }
        else {
            notification.error({ message: "must select one or more users" });
        }
    };

    const showModal = () => {
        console.log(selectedUsers);
        if (selectedUsers.users.length > 0) {
            setIsBulkModalOpen(false);
            setIsModalOpen(true);
        }
        else {
            notification.error({ message: "must select one or more users" });
        }
    };

    const showBulkModal = () => {
        setIsModalOpen(false);
        setIsBulkModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedUsers({
            users: []
        });
    };
    const handleBulkCancel = () => {
        setIsBulkModalOpen(false);
    };

    const sendPushNotification = (values) => {
        post(`push-notifications`, {
            "user_ids": selectedUsers.users,
            "notification_title": values.notification_title,
            "notification_body": values.notification_body
        }).then((res) => {
            notification.success({ message: "Notification Sent successfully" });

            NotificationForm.resetFields();
            setIsModalOpen(false);
            setSelectedUsers({
                users: []
            });
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };
    const sendBulkPushNotification = (values) => {
        post(`all-users-push-notification`, {
            "notification_title": values.notification_title,
            "notification_body": values.notification_body
        }).then((res) => {
            notification.success({ message: "Notification Sent successfully" });

            BulkNotificationForm.resetFields();
            setIsBulkModalOpen(false);
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };


    const handelApproveUsers = () => {
        if (selectedUsers.users.length > 0) {
            post(`users-bulk-action`, {
                users: selectedUsers.users,
                action: "is_approved",
                "value": true,
            }).then((res) => {
                notification.success({ message: "Users Approved successfully" });

                setTimeout(() => {
                    window.location.reload();
                }, 3000)
            })
                .catch((err) => {
                    notification.error({
                        message: "Error!",
                        description: err.response.data.message,
                    });
                    setLoading(false);
                });
        }
        else {
            notification.error({ message: "must select one or more users" });
        }
    };
    const onChangeDate = (date, dateString) => {
        console.log(date, dateString);
        setFilterData({
            filter_key: filterData.filter_key,
            filter_value: dateString,
        })
    };


    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedUsers({
                users: selectedRowKeys,
            });
        },
        getCheckboxProps: (record) => ({
            name: record.id,
        }),
    };

    return (
        <>
            <Modal title="Push Notification" open={isModalOpen}
                footer={null} forceRender onCancel={handleCancel}>
                <Form
                    form={NotificationForm}
                    className="wrapper-content"
                    onFinish={sendPushNotification}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    autoComplete="off">
                    <div className="row">
                        {/* Item */}
                        <div className="col-lg-12">
                            <Form.Item
                                label="Title"
                                name="notification_title"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-12">
                            <Form.Item
                                label="Body "
                                name="notification_body"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <TextArea showCount
                                    maxLength={250}
                                    style={{
                                        height: 120,
                                    }} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Send
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Modal>
            <Modal title="Bulk Notification" open={isBulkModalOpen}
                footer={null} forceRender onCancel={handleBulkCancel}>
                <Form
                    form={BulkNotificationForm}
                    className="wrapper-content"
                    onFinish={sendBulkPushNotification}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    autoComplete="off">
                    <div className="row">
                        {/* Item */}
                        <div className="col-lg-12">
                            <Form.Item
                                label="Title"
                                name="notification_title"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-12">
                            <Form.Item
                                label="Body "
                                name="notification_body"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <TextArea showCount
                                    maxLength={250}
                                    style={{
                                        height: 120,
                                    }} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Send
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Modal>

            <Tabs onChange={function (activeKey) { handleUserType(activeKey) }}>
                <TabPane tab="Approved" key="approved" >
                    <div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row">
                                    {checkPermission("update-user") && total > 0 ? (
                                        <div className="text-end">
                                            <Button type="primary"
                                                onClick={bulkActivateUsers}
                                                className="p-2 mb-2"
                                                size="large">
                                                {" "}
                                                Active
                                                {" "}
                                            </Button>
                                            {
                                                checkPermission("push-notifications") && total > 0 ? (
                                                    <>
                                                        {
                                                            selectedUsers.users.length > 0 ? (
                                                                <Button className="p-2 m-2" size="large"
                                                                    type="primary" onClick={showModal}>
                                                                    Send Notification to selected
                                                                </Button>
                                                            ) : (
                                                                <Button className="p-2 m-2" size="large"
                                                                    type="primary" onClick={showBulkModal}>
                                                                    Send Notification to all
                                                                </Button>
                                                            )
                                                        }
                                                    </>
                                                ) : (<></>)
                                            }
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="grid-margin stretch-card wrapper-content">
                            <Form onFinish={onFilter} layout="vertical" form={form}>
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <Form.Item
                                            label="Search By"
                                            name="filter_key"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "This field is required!",
                                                },
                                            ]}
                                        >
                                            <Select
                                                className="w-100"
                                                showSearch
                                                placeholder="select test "
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? "")
                                                        .toLowerCase()
                                                        .includes(input.toLowerCase())
                                                }
                                                onChange={handleFilterKey}
                                                options={[
                                                    {
                                                        value: "phone_number",
                                                        label: "Phone",
                                                    },
                                                    {
                                                        value: "email",
                                                        label: "Email",
                                                    },
                                                    {
                                                        value: "created_at",
                                                        label: "Register Date",
                                                    },
                                                    {
                                                        value: "name",
                                                        label: "Name",
                                                    },
                                                    {
                                                        value: "id",
                                                        label: "Id",
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <Form.Item
                                            label="Search"
                                            name="filter_value"
                                            onChange={handleFilterVal}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "This field is required!",
                                                },
                                            ]}
                                        >

                                            {
                                                filterData.filter_key === "created_at" ?
                                                    <DatePicker className="w-100" onChange={onChangeDate} />
                                                    :
                                                    <Input placeholder="Type to search..." />
                                            }


                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-lg-2 d-flex align-items-end">
                                        <Form.Item className="w-100">
                                            <div className="row">
                                                <div className="col-8">
                                                    <Button
                                                        block
                                                        loading={loadingSearch}
                                                        type="primary"
                                                        htmlType="submit"
                                                    >
                                                        {loadingSearch ? "Searching" : "Search"}
                                                    </Button>
                                                </div>
                                                <div className="col-4">
                                                    <Button
                                                        onClick={clearSearch}
                                                        className="flex-center"
                                                        danger
                                                        type="primary"
                                                    >
                                                        <DeleteOutlined />
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                            <div className="w-100">
                                <Table
                                    onChange={onChangeTable}
                                    locale={{ emptyText: "There are no data" }}
                                    loading={loading}
                                    pagination={{
                                        position: ["bottomCenter"],
                                        size: "large",
                                        defaultPageSize: 50,
                                        total: total,
                                        showSizeChanger: true,
                                        showQuickJumper: true,
                                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                        pageSizeOptions: ['50', '100', '200', '500', '1000']
                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    size="middle"
                                    rowKey={record => record.id}
                                    rowSelection={{
                                        type: "checkbox",
                                        ...rowSelection,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane tab="Waiting" key="waiting">
                    <div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row">
                                    {checkPermission("update-user") && total > 0 ? (
                                        <div className="text-end">
                                            <Button type="primary"
                                                onClick={handelApproveUsers}
                                                className="p-2 mb-2"
                                                size="large">
                                                {" "}
                                                Approve
                                                {" "}
                                            </Button>
                                            {
                                                checkPermission("push-notifications") && total > 0 ? (
                                                    <>
                                                        {
                                                            selectedUsers.users.length > 0 ? (
                                                                <Button className="p-2 m-2" size="large"
                                                                    type="primary" onClick={showModal}>
                                                                    Send Notification to selected
                                                                </Button>
                                                            ) : (
                                                                <Button className="p-2 m-2" size="large"
                                                                    type="primary" onClick={showBulkModal}>
                                                                    Send Notification to all
                                                                </Button>
                                                            )
                                                        }

                                                    </>
                                                ) : (<></>)
                                            }
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="grid-margin stretch-card wrapper-content">
                            <Form onFinish={onFilter} layout="vertical" form={form}>
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <Form.Item
                                            label="Search By"
                                            name="filter_key"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "This field is required!",
                                                },
                                            ]}
                                        >
                                            <Select
                                                className="w-100"
                                                showSearch
                                                placeholder="select test "
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? "")
                                                        .toLowerCase()
                                                        .includes(input.toLowerCase())
                                                }
                                                onChange={handleFilterKey}
                                                options={[
                                                    {
                                                        value: "phone_number",
                                                        label: "Phone",
                                                    },
                                                    {
                                                        value: "email",
                                                        label: "Email",
                                                    },
                                                    {
                                                        value: "created_at",
                                                        label: "Register Date",
                                                    },
                                                    {
                                                        value: "name",
                                                        label: "Name",
                                                    },
                                                    {
                                                        value: "id",
                                                        label: "Id",
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <Form.Item
                                            label="Search"
                                            name="filter_value"
                                            onChange={handleFilterVal}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "This field is required!",
                                                },
                                            ]}
                                        >

                                            {
                                                filterData.filter_key === "created_at" ?
                                                    <DatePicker className="w-100" onChange={onChangeDate} />
                                                    :
                                                    <Input placeholder="Type to search..." />
                                            }


                                        </Form.Item>
                                    </div>
                                    <div className="col-12 col-lg-2 d-flex align-items-end">
                                        <Form.Item className="w-100">
                                            <div className="row">
                                                <div className="col-8">
                                                    <Button
                                                        block
                                                        loading={loadingSearch}
                                                        type="primary"
                                                        htmlType="submit"
                                                    >
                                                        {loadingSearch ? "Searching" : "Search"}
                                                    </Button>
                                                </div>
                                                <div className="col-4">
                                                    <Button
                                                        onClick={clearSearch}
                                                        className="flex-center"
                                                        danger
                                                        type="primary"
                                                    >
                                                        <DeleteOutlined />
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                            <div className="w-100">
                                <Table
                                    onChange={onChangeTable}
                                    locale={{ emptyText: "There are no data" }}
                                    loading={loading}
                                    pagination={{
                                        position: ["bottomCenter"],
                                        size: "large",
                                        defaultPageSize: 50,
                                        total: total,
                                        showSizeChanger: true,
                                        showQuickJumper: true,
                                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                        pageSizeOptions: ['50', '100', '200', '500', '1000']
                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    size="middle"
                                    rowKey={record => record.id}
                                    rowSelection={{
                                        type: "checkbox",
                                        ...rowSelection,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </>
    );
};

export default Users;
