import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Select, Form, notification } from "antd";
import { get, post } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const AddAdmin = () => {
    const navigate = useNavigate();
    const [dataRoles, setDataRoles] = useState([]);
    const [optionRoles, setOptionRoles] = useState([]);

    useEffect(() => {
        setOptionRoles(
            dataRoles.map((d) => ({
                value: d.id,
                label: d.name,
            }))
        );
    }, [dataRoles]);

    useEffect(() => {
        get("get-all-roles").then((res) => {
            setDataRoles(res.data);
        });
    }, []);

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        

        post("admins", values)
            .then((res) => {
                notification.success({
                    message: "Added successfully"
                });
                setLoading(false);
                navigate("/admins");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-4">
                <Link className="text-dark me-3" to="/admins">
                    <ArrowLeftOutlined />
                </Link>
                Add Admin
            </Title>
            <Form
                className="wrapper-content"
                onFinish={onFinish}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className="row">
                    <div className="col-lg-12">
                        <Form.Item
                            label="Name "
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>

                    <div className="col-lg-12">
                        <Form.Item
                            label="Email "
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                                {
                                    type: "email",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>

                    <div className="col-lg-12">
                        <Form.Item
                            label="Password "
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input.Password placeholder="Type..." />
                        </Form.Item>
                    </div>

                    <div className="col-lg-12">
                        <Form.Item
                            label="Role"
                            name="role"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Select
                                optionFilterProp="children"
                                className="w-100"
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                placeholder="Select"
                                options={optionRoles}
                            />
                        </Form.Item>
                    </div>

                    <div className="col-lg-4 offset-lg-4">
                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size="large"
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                {loading ? "Saving..." : "Save"}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddAdmin;
