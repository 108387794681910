import React, { useEffect, useState } from "react";
import {
    Typography,
    Button,
    Spin,
    Form,
    Descriptions,
    Divider,
    notification,
} from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";

const { Title } = Typography;

const EditFeedback = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});
    const [userData, setUserData] = useState({});
    const [userMessage, setUserMessage] = useState({});
    const [adminResponse, setAdminResponse] = useState({});

    useEffect(() => {
        get(`feedbacks/${id}`).then((res) => {
            setInputsValue({
                'comment' : res.data.comment
            });

            setUserData(res.data.user)
            setUserMessage(res.data.message)
            setAdminResponse({
                'comment': res.data.comment,
                'contacted_date': res.data.contacted_date,
                'response_by': res.data.response_by,
                'response_time': res.data.response_time,
            })
            setFromLoading(true);
        });
    }, [id]);

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
    setLoading(true);

        put(`feedbacks/${id}`, values)
            .then((res) => {
                notification.success({message:"Modified successfully"});
                setLoading(false);
                navigate("/feedbacks");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/feedbacks">
                    <ArrowLeftOutlined />
                    Edit Feedback
                </Link>
            </Title>

            {fromLoading ? 
                
                <>
                 <Divider /> 
                    <Descriptions title="User Info" bordered colon className="bg-white p-2">
                        <Descriptions.Item label="UserName">
                            { userData.name ?? "" }
                        </Descriptions.Item>
                        <Descriptions.Item label="Telephone">
                            { userData.phone ?? "" }
                        </Descriptions.Item>
                    </Descriptions>
                     <Divider /> 
                    <Descriptions title="User Message" bordered colon className="bg-white p-2">
                        <Descriptions.Item label="Message">
                            { userMessage ?? '' }
                        </Descriptions.Item>
                    </Descriptions>
                     <Divider /> 
                    {
                        adminResponse.comment !== '' ?
                        
                            (                                
                                <Descriptions title="Admin Comment" bordered colon className="bg-white p-2">
                                    <Descriptions.Item label="Comment" bordered >
                                        { adminResponse.comment ?? '' }
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Contacted date" bordered >
                                        { adminResponse.contacted_date ?? '' }
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Response Time" bordered>
                                        { adminResponse.response_time ?? '' }
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Response By" bordered>
                                        { adminResponse.response_by ?? '' }
                                    </Descriptions.Item>
                                </Descriptions>
                            )
                    : ''
                    }

                     <Divider />
                    <Form
                        className="wrapper-content"
                        onFinish={onFinish}
                        labelCol={{
                            span: 7,
                        }}
                        wrapperCol={{
                            span: 15,
                        }}
                        initialValues={{
                             comment: inputsValue.comment,
                        }}
                        autoComplete="off"
                    >
                        <div className="row">
                            <div className="col-lg-12">
                                <Form.Item
                                    label="Comment"
                                    name="comment">
                                    <ReactQuill theme="snow"  />
                                </Form.Item>
                            </div>
                            
                            <div className="col-lg-4 offset-lg-4">
                                <Form.Item
                                    wrapperCol={{
                                        offset: 1,
                                        span: 16,
                                    }}
                                >
                                    <Button
                                        loading={loading}
                                        size="large"
                                        block
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {loading ? "Saving..." : "Sava"}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </>
                
             : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default EditFeedback;
