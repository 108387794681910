import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Spin, Form, notification } from "antd";
import { get, post } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EditMessagesTranslation = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});

    useEffect(() => {
        get(`show-message-translation?key=${id}`).then((res) => {
            setInputsValue(res.data);
            setFromLoading(true);
        });
    }, [id]);

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        post(`messages-translations`, {
            ...values,
            "key": inputsValue.key
        })
            .then((res) => {
                notification.success({message:"edited successfully"});
                setLoading(false);
                navigate("/messages-translations");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-4">
                <Link className="text-dark me-3" to="/messages-translations">
                    <ArrowLeftOutlined />
                    Edit message translation
                </Link>
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        name: inputsValue.name,
                        key: inputsValue.key,
                        en: inputsValue.en,
                        ar: inputsValue.ar
                    }}
                    autoComplete="off"
                >
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>{inputsValue.name} <small>translations</small></h4>
                            <hr />
                        </div>
                        <div className="col-lg-6">
                            <Form.Item
                                label="Translation En"
                                name="en"
                                extra="Notice: Please don't change any word starts with :"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="col-lg-6">
                            <Form.Item
                                label="Translation ar"
                                name="ar"
                                extra="Notice: Please don't change any word starts with :"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading ? "Updating..." : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default EditMessagesTranslation;
