import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, notification } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { post } from "../../../api/axios";

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    // const navigate = useNavigate();

    // const [loginData, setLoginData] = useState({ email: "" });

    // const handleChange = (e) => {
    //     setLoginData({ ...loginData, [e.target.id]: e.target.value });
    // };


    useEffect(() => {
        const loggedInAdmin = localStorage.getItem("loggedInAdmin");
        if (loggedInAdmin) {
            window.location.href = "/";
        }
    }, []);

    const onFinish = (values) => {
        setLoading(true);

        post("auth/reset-password", values)
            .then((res) => {
                message.success("sent succesfully");
                // localStorage.setItem("tokenAdmin", res.data.credentials.access_token);
                // localStorage.setItem(
                //     "permissionsAdmin",
                //     JSON.stringify(res.data.profile.permissions)
                // );
                // localStorage.setItem("loggedInAdmin", true);
                // localStorage.setItem("authDataAdmin", JSON.stringify(res.data.profile));

                // window.location.href = "/";
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };



    return (
        <div className="login-section">
            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-7 col-lg-5">
                            <div className="login-wrap p-4 p-md-5">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <UserOutlined />
                                </div>
                                <h3 className="text-center mb-4"> Forget Password </h3>
                                <Form
                                    className="login-form"
                                    layout="vertical"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                >

                                    <Form.Item
                                        label='New Password'
                                        name='new_password'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'This field is required!',
                                            },
                                        ]}
                                    >
                                        <Input.Password placeholder='Type...' />
                                    </Form.Item>

                                    <Form.Item
                                        label='New Password Confirmation'
                                        name='new_password_confirmation'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'This field is required!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('new_password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The new password that you entered do not match!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password placeholder='Type...' />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button
                                            className="form-control btn btn-primary rounded submit mb-5 px-3"
                                            type="primary"
                                            block
                                            htmlType="submit"
                                            loading={loading}
                                        >
                                            {loading ? "Loading..." : "Submit"}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ResetPassword;
